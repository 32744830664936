#land {

    .header {
        height: 256px;
        min-height: 256px;
        max-height: 256px;
        background: url('/assets/images/backgrounds/november.jpg') no-repeat 0 45%;
        background-size: 100% auto;

        .profile-image {
            margin-right: 24px;
        }

        .name {
            font-size: 34px;
            color: #FFFFFF;
        }

        .actions {

            .md-button {
                text-transform: none;
                padding: 0 16px;
                height: 32px;
                line-height: 32px;
                margin: 0 0 0 8px;
            }
        }
    }

    .content {

        md-content {
            background-color: transparent;
        }
    }

    // Profile boxes
    .profile-box {
        margin-bottom: 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #profile {

        .header {

            .profile-image {
                margin: 0 0 16px 0;
            }

            .name {
                margin-bottom: 32px;
            }
        }
    }

}