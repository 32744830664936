#land {
    .md-card-image {
        max-height: 300px;
        min-height: 300px;
        object-fit: cover;
    }
    .simple {
        font-size: 16px;
    }
    th {
        text-align: center;
    }
    .profile-box {
        font-size: 16px;
    }
    .huge2 {
        margin: 0px;
        width: 96px;
        height: 96px;
        margin-right: 24px;
        object-position: center;
    }
}